/* アーティストの画像1枚のときのスタイル */
.image-container-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* アーティストの画像が2枚のときのスタイル */
.image-container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

/* アーティストの画像が3枚のときのスタイル */
.image-container-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  place-items: center;
  gap: 5px;
}

.image-container-3 img:first-child,
.image-container-3 img:nth-child(2) {
  grid-row: 1;
}

.image-container-3 img:last-child {
  grid-row: 2;
  grid-column: 1 / span 2;
}

/* アーティストの画像が4枚のときのスタイル */
.image-container-4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 5px;
}

.input-info {
  border-color: #2ea9df !important;
}

.sidebar-link {
  display: block;
  font-size: 1.3em;
  border-radius: 10px;
}

.sidebar-link:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
